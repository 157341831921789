import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import menuItems from './MenuItems';
import TopBar from './TopBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Use Chakra UI's useBreakpointValue to get the current breakpoint
  const isMobile = useBreakpointValue({ base: true, lg: false });
 
  return (
    <>
    {isAuthenticated ?
    <Flex flexDirection={{ base: 'column', lg: 'row' }} bg={'#f7f9fc'} color={'#6c757d'}>
      {isMobile ? (
        <>
          <TopBar onToggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} mb={100} />
          <Flex>
          <Sidebar isSidebarOpen={isSidebarOpen} menuItems={menuItems} />
          </Flex>
        </>
      ) : (
        <Sidebar isSidebarOpen={isSidebarOpen} menuItems={menuItems}  />
      )}

      <Box
        as="section"
        flex="1"
        display="flex"
        flexDirection="column"
        fontSize={'13px'}
        background={'#f7f9fc'}
        color={'#6c757d'}
      >
        {!isMobile && (
          <TopBar onToggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        )}
        <Box 
        // p={isMobile ? '0' : '2.5rem 3.5rem 1.5rem'}
        >{children}</Box>
      </Box>
    </Flex> : 
          navigate('/login')
        }
    </>
  );
};

export default DashboardLayout;
