import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Flex,
  Text,
  Spinner,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';
import DashboardLayout from '../../common/components/DashboardLayout';
import useApi from "../../common/hooks/useApi";
import { useLocation, Link } from 'react-router-dom';
import { submitForm } from '../../common/api';

const AccountPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [types, setType] = useState();
  const [status, setStatus] = useState();
  const [id, setId] = useState();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [accountTypeFilter, setAccountTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [relationshipOfficerFilter, setRelationshipOfficerFilter] = useState('');
  const [aftaCategoryFilter, setAftaCategoryFilter] = useState('');

  const { loading, error, execute } = useApi(submitForm);
  const items = ["A", "C", "E", "L", "S"];

  // Categorize status helper function
  const categorize = (status) => {
    if (status === "active") {
      return "'A'";
    } else if (status === "all") {
      return items.map(item => `'${item}'`).join(", ");
    } else if (status === "dormant") {
      return items.filter(item => item !== "A").map(item => `'${item}'`).join(", ");
    }
  };

  // Helper functions
  const getStatus = (lastPaymentDate) => {
    const now = new Date();
    const lastPayment = new Date(lastPaymentDate);
    const daysDiff = (now - lastPayment) / (1000 * 60 * 60 * 24);
    return daysDiff > 5 ? 'Non-Performing' : 'Performing';
  };

  // Extract unique filter options
  const uniqueAccountTypes = [...new Set(data.map((item) => item.PROD_DESC))];
  const uniqueStatuses = ['Performing', 'Non-Performing']; // Static as derived from getStatus
  const uniqueRelationshipOfficers = [...new Set(data.map((item) => item.REL_OFFICER_PREF_NAME))];
  const uniqueAftaCategories = [...new Set(data.map((item) => item.AFTA_CATEGORY))];

  useEffect(() => {
    const url = window.location.href;
    const urlSegments = new URL(url).pathname.split('/');
    
    setType(urlSegments[2]);
    setStatus(urlSegments[3]);
    setId(urlSegments[4]);
  
  }, []);

  useEffect(() => {
    const groupParam = query.get('group');
    const group = groupParam ? groupParam.split(',') : [];
    console.log({ group }, "Query Params");
    const loadData = async () => {
      try {
        const result = await execute("fetch/fetchStatus", {
          id,
          permission: types || "overall",
          status: categorize(status),
          group
        });
        console.log(result, "result")
        if (result?.data) {
          setData(result?.data?.map((item) => ({
            ...item,
            status: categorize(status),
          })));
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (id && types && status) {
      loadData();
    }
    console.log(id, types, categorize(status), "test")
  }, [id, types, status]);


  // Filter data
  const filteredData = data
    .filter((item) =>
      item.ACCT_NM.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ACCT_NO.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.BU_NM.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.PROD_DESC.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((item) =>
      (!accountTypeFilter || item.PROD_DESC === accountTypeFilter) &&
      (!statusFilter || item.STATUS === statusFilter) &&
      (!relationshipOfficerFilter || item.RELATIONSHIP_OFFICER === relationshipOfficerFilter) &&
      (!aftaCategoryFilter || item.AFTA_CATEGORY === aftaCategoryFilter)
    );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <DashboardLayout>
      <Box p={10}>
        <Box mb={4}>
          <SimpleGrid columns={5} width={"70%"}>
          <Input
            placeholder="Search by name, account number, or business unit"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            // width="300px"
            fontSize="sm"
          />
          <Select
            placeholder="Filter by Account Type"
            value={accountTypeFilter}
            onChange={(e) => setAccountTypeFilter(e.target.value)}
            // width="200px"
            fontSize="sm"
          >
            {uniqueAccountTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Filter by Status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            // width="200px"
            fontSize="sm"
          >
            {uniqueStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Filter by Relationship Officer"
            value={relationshipOfficerFilter}
            onChange={(e) => setRelationshipOfficerFilter(e.target.value)}
            // width="200px"
            fontSize="sm"
          >
            {uniqueRelationshipOfficers.map((officer) => (
              <option key={officer} value={officer}>
                {officer}
              </option>
            ))}
          </Select>
          <Select
            placeholder="Filter by AFTA Category"
            value={aftaCategoryFilter}
            onChange={(e) => setAftaCategoryFilter(e.target.value)}
            // width="200px"
            fontSize="sm"
          >
            {uniqueAftaCategories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
          </SimpleGrid>
        </Box>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" my={4}>
            <Spinner size="lg" />
          </Flex>
        ) : (
          <Table variant="striped" colorScheme="gray" size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Account Number</Th>
                <Th>Business Unit</Th>
                <Th>Account Type</Th>
                <Th>AFTA Category</Th>
                <Th>Relationship Officer</Th>
                <Th>Current Balance</Th>
                <Th>Last Payment</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                    <Link to={`/account_history/${item.ACCT_NO}`} style={{ color: 'blue', textDecoration: 'underline' }}>
                      {item.ACCT_NM}
                      </Link>
                      </Td>
                    <Td>{item.ACCT_NO}</Td>
                    <Td>{item.BU_NM}</Td>
                    <Td>{item.PROD_DESC}</Td>
                    <Td>{item.AFTA_CATEGORY}</Td>
                    <Td>{item.REL_OFFICER_PREF_NAME}</Td>
                    <Td>{item.CURRENT_BAL?.toLocaleString()}</Td>
                    <Td>{item.LAST_DEPOSIT_DATE}</Td>
                    <Td>{item.STATUS}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="8" textAlign="center">
                    No Data Found
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
        <Flex mt={4} justifyContent="center" alignItems="center">
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            isDisabled={currentPage === 1}
            mr={2}
          >
            Previous
          </Button>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            isDisabled={currentPage === totalPages}
            ml={2}
          >
            Next
          </Button>
        </Flex>
      </Box>
    </DashboardLayout>
  );
};

export default AccountPage;
