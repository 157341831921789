import React, { useState, useEffect, useRef } from "react";
import { Box, Select } from "@chakra-ui/react";
import _ from "lodash";

const ChartComponent = ({ categories, dataset, type }) => {
  const [viewMode, setViewMode] = useState("compare"); // Default to 'compare' for all datasets
  const [tooltip, setTooltip] = useState(null);
  const canvasRef = useRef(null);

  const datasetNames = dataset?.map((d) => d.name);

  // Define the handleMouseMove function before using it in debouncedHandleMouseMove
  const handleMouseMove = (points, chartHeight, padding) => {
    return (e) => {
      const rect = e.target.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      let hoveredPoint = null;
      points.forEach((point) => {
        const radius = 5;
        if (Math.sqrt((mouseX - point.x) ** 2 + (mouseY - point.y) ** 2) <= radius) {
          hoveredPoint = point;
        }
      });

      if (hoveredPoint) {
        const tooltipContent = `
          <strong>${hoveredPoint.dataset.name}</strong><br>
          ${hoveredPoint.label}: ${hoveredPoint.value.toLocaleString()}`;

        const tooltipPosition = {
          left: mouseX + 10, 
          top: mouseY - 40,
        };

        setTooltip({
          content: tooltipContent,
          position: adjustTooltipPosition(tooltipPosition, 100, 40),
        });
      } else {
        setTooltip(null);
      }
    };
  };

  // Initialize the debounced mousemove handler with useRef
  const debouncedHandleMouseMove = useRef(
    _.debounce(handleMouseMove, 50) // Adjust the delay time as needed
  ).current;

  useEffect(() => {
    if (canvasRef?.current && categories?.length > 0 && dataset?.length > 0) {
      drawChart({ categories, data: dataset, canvas: canvasRef.current, viewMode });
    }
  }, [viewMode, categories, dataset]);

  const drawChart = ({ categories, data, canvas, viewMode }) => {
    const ctx = canvas.getContext("2d");
    const chartWidth = canvas.width || "100%";
    const chartHeight = canvas.height || "100%";
    const padding = 50;
  
    ctx.clearRect(0, 0, chartWidth, chartHeight);
  
    if (categories.length === 0 || data.length === 0) {
      ctx.font = "16px Arial";
      ctx.fillText("No data available", chartWidth / 2 - 60, chartHeight / 2);
      return;
    }
  
    // Determine max data based on the viewMode
    let maxData;
    if (viewMode === "compare") {
      // In compare mode, use the max value from all datasets
      maxData = Math.max(...data.flatMap((d) => d.data));
    } else {
      // In non-compare mode, only consider the selected dataset
      const selectedDataset = data.find((datasetItem) => datasetItem.name === viewMode);
      if (selectedDataset) {
        maxData = Math.max(...selectedDataset.data);
      } else {
        return; // No valid dataset found for the selected viewMode
      }
    }
  
    const pointSpacing = (chartWidth - 2 * padding) / (categories?.length - 1);
    const points = [];
  
    // Draw the Y-axis
    const yAxisSpacing = 10;
    const numYTicks = 5;
    const yTickInterval = Math.ceil(maxData / numYTicks);
    
    // Y-Axis tick marks and labels
    ctx.font = "12px Arial";
    ctx.fillStyle = "black";
    
    // Draw Y-Axis grid lines and ticks
    for (let i = 0; i <= numYTicks; i++) {
      const yValue = i * yTickInterval;
      const yPosition = chartHeight - padding - (yValue / maxData) * (chartHeight - 2 * padding);
  
      // Draw the Y-axis grid line
      ctx.beginPath();
      ctx.moveTo(padding, yPosition);
      ctx.lineTo(chartWidth - padding, yPosition);
      ctx.strokeStyle = "rgba(0, 0, 0, 0.1)";
      ctx.lineWidth = 1;
      ctx.stroke();
  
      // Draw the Y axis tick
      ctx.beginPath();
      ctx.moveTo(padding - 5, yPosition);
      ctx.lineTo(padding, yPosition);
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.stroke();
  
      // Label for Y-axis
      ctx.fillText(yValue.toLocaleString(), padding - 35, yPosition + 5);
    }
  
    // Draw the chart lines and points
    data.forEach((datasetItem) => {
      if (viewMode === "compare" || viewMode === datasetItem.name) {
        const chartType = type; // Set to line chart by default (can be modified to add bar or area if needed)
        
        if (chartType === "line") {
          ctx.beginPath();
          datasetItem?.data?.forEach((value, i) => {
            const x = padding + i * pointSpacing;
            const y = chartHeight - padding - (value / maxData) * (chartHeight - 2 * padding);
            points.push({ x, y, value, label: categories[i], dataset: datasetItem });
    
            if (i === 0) ctx.moveTo(x, y);
            else ctx.lineTo(x, y);
    
            ctx.strokeStyle = datasetItem.color;
            ctx.lineWidth = 2;
          });
    
          ctx.stroke();
    
          // Draw the data points (circles)
          datasetItem?.data?.forEach((value, i) => {
            const x = padding + i * pointSpacing;
            const y = chartHeight - padding - (value / maxData) * (chartHeight - 2 * padding);
    
            ctx.beginPath();
            ctx.arc(x, y, 4, 0, 2 * Math.PI);
            ctx.fillStyle = datasetItem.color;
            ctx.fill();
            ctx.stroke();
          });
        } else if (chartType === "bar") {
          datasetItem?.data?.forEach((value, i) => {
            const barWidth = pointSpacing * 0.6; // Adjust width of bars
            const x = padding + i * pointSpacing - barWidth / 2;
            const y = chartHeight - padding - (value / maxData) * (chartHeight - 2 * padding);
            const height = (value / maxData) * (chartHeight - 2 * padding);
    
            // Draw bar
            ctx.fillStyle = datasetItem.color;
            ctx.fillRect(x, y, barWidth, height);
          });
        } else if (chartType === "area") {
          ctx.beginPath();
          datasetItem?.data?.forEach((value, i) => {
            const x = padding + i * pointSpacing;
            const y = chartHeight - padding - (value / maxData) * (chartHeight - 2 * padding);
            points.push({ x, y, value, label: categories[i], dataset: datasetItem });
    
            if (i === 0) ctx.moveTo(x, y);
            else ctx.lineTo(x, y);
    
            ctx.strokeStyle = datasetItem.color;
            ctx.lineWidth = 2;
          });
    
          ctx.stroke();
    
          // Fill the area below the line
          ctx.lineTo(chartWidth - padding, chartHeight - padding);
          ctx.lineTo(padding, chartHeight - padding);
          ctx.closePath();
    
          const color = datasetItem.color;
          const rgbaColor = hexToRgba(color, 0.3);
          ctx.fillStyle = rgbaColor;
          ctx.fill();
        }
      }
    });
  
    // Draw the X-axis labels (categories)
    ctx.font = "12px Arial";
    categories.forEach((category, i) => {
      const x = padding + i * pointSpacing;
      const y = chartHeight - padding + 20;
      ctx.fillText(category, x - 15, y);
    });
  
    // Add event listeners after drawing
    canvas.addEventListener("mousemove", debouncedHandleMouseMove(points, chartHeight, padding));
    canvas.addEventListener("mouseout", () => setTooltip(null));
  };
  
  const adjustTooltipPosition = (tooltipPosition, tooltipWidth, tooltipHeight) => {
    const canvasRect = canvasRef.current.getBoundingClientRect();
    const maxX = canvasRect.width - tooltipWidth;
    const maxY = canvasRect.height - tooltipHeight;
    return {
      left: Math.min(tooltipPosition.left, maxX),
      top: Math.min(tooltipPosition.top, maxY),
    };
  };
  
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <Box>
      <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <Select
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
          width="200px"
        >
          <option value="compare">Compare All</option>
          {datasetNames.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </Box>

      <Box
        id="chartWrapper"
        mt={4}
        position="relative"
        onMouseOut={() => setTooltip(null)}
      >
        <canvas ref={canvasRef} width="800" height="400" />
        {tooltip && (
          <div
            style={{
              position: "absolute",
              top: tooltip.position.top,
              left: tooltip.position.left,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "10px",
              borderRadius: "5px",
              pointerEvents: "none",
              zIndex: 999,
            }}
            dangerouslySetInnerHTML={{ __html: tooltip.content }}
          />
        )}
      </Box>
    </Box>
  );
};
export default ChartComponent;
