import React, { useState } from "react";
import { Select, FormControl, Grid, useMediaQuery } from "@chakra-ui/react";
import CustomSelect from "./CustomSelect";

const SelectByPermission = ({ userPermission , user, handleSelectChangeCustom, page}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const overallPermission = userPermission?.permissions?.find(permission => permission?.name.includes("Overall"));
  const territorialPermission =  userPermission?.permissions?.find(permission => permission?.name.includes("Territorial"));
  const branchPermission =  userPermission?.permissions?.find(permission => permission?.name.includes("Branch"));
  const teamPermission =  userPermission?.permissions?.find(permission => permission?.name.includes("Team"));
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const handleSelectChange = (event) => {
    console.log(event.target.value)
    setSelectedOption(event.target.value);
  };
  
  return (
    <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} mb={6}>
      <FormControl id="selectOption">
        <Select
          placeholder="Select an option"
          value={selectedOption}
          onChange={handleSelectChange} 
          variant="filled"
          bg="white"
          border="1px solid grey.300"
          fontSize={12}
        >
            { page == "mass" ? (
              <>
              <option value="business_unit">Business Unit</option>
              <option value="markets">Markets</option>
              </>
            ): overallPermission ? (
            <>
                <option value="overall">Overall</option>
                <option value="territories">Territory</option>
                <option value="business_unit">Business Unit</option>
                <option value="users">Users</option>
                <option value="markets">Markets</option>
            </>
            ): territorialPermission ? (
                <>
                <option value="business_unit">Business Unit</option>
                <option value="users">Users</option>
                <option value="markets">Markets</option>
            </>
            ) : branchPermission ? ( 
            <>
                <option value="users">Users</option>
                <option value="markets">Markets</option>
            </>) : teamPermission ? ( 
            <>
                <option value="team">Users</option>
                <option value="markets">Markets</option>
            </>) : null }
            
        </Select>
      </FormControl>
      
      {selectedOption === "territories" && (
        <CustomSelect
          id="territories"
          url="auth/territories"
          api="Auth API"
          handleSelectChange={handleSelectChangeCustom}
        />
      )}
      {selectedOption === "business_unit" && (
        <CustomSelect
          id="businessunit"
          url={territorialPermission ? `auth/business_unit/sharedTerritories/${user?.businessUnit?.territory}` : "auth/business_unit"}
          api="Auth API"
          handleSelectChange={handleSelectChangeCustom}
        />
      )}
      
      {selectedOption === "users" && (
        <CustomSelect
          id="users"
          url={branchPermission ? `auth/users/branch/${user?.businessUnit?._id}` : territorialPermission ? `auth/users/territories/${user?.businessUnit?.territory}` :`auth/users/list`}
          api="Auth API"
          handleSelectChange={handleSelectChangeCustom}
        />
      )}
       {selectedOption === "team" && (
        <CustomSelect
          id="users"
          // ids="users"
          url={teamPermission && `auth/users/team/${user?.businessUnit?.team}`}
          api="Auth API"
          handleSelectChange={handleSelectChangeCustom}
        />
      )}
      {selectedOption === "markets" && (
      <CustomSelect
          id="markets"
          url={`markets`}
          api="Savings API"
          handleSelectChange={handleSelectChangeCustom}
        />)}
   </Grid>
  );
}

export default SelectByPermission;
