import { Box, Spinner, Grid, Text, Select, SimpleGrid, Table, Tr, Td, Th, Tbody, Thead, useMediaQuery, Button } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import DashboardLayout from '../../common/components/DashboardLayout';
import { useSelector } from 'react-redux';
import SelectByPermission from '../../common/components/SelectByPermission';
import useFormSubmission from '../../common/hooks/useFormSubmission';
import ReportHeader from '../components/ReportHeader';
import ChartComponent from '../../common/components/ChartComponent';

const TrendAnalysisPage = () => {
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const handleProductsSelected = (selectedIds) => {
    setSelectedProductIds(selectedIds);
  };

  const [analysisType, setAnalysisType] = useState('month');
  const userPermission = useSelector((state) => state?.auth?.user?.role);
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState();
  const { user } = useSelector((state) => state.auth);
  let [ids, setIds] = useState(user?._id);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  let [filterValue, setFilterValue] = useState('today'); 
  const [accountOpened, setAccounts] = useState();
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    const fetchDat = async () => {    
      try {
        await dataFetch(userPermission?._id, analysisType, ids, permission);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };  
    fetchDat();
  }, [analysisType]);

  const handleSelectChange = async(event, selectId) => {
    setIds(event);
    setPermission(selectId);
  }; 

  const searchButton = async () => {
    await dataFetch(userPermission?._id, analysisType, ids, permission);
  };

  const { submitError, submitForm } = useFormSubmission("Savings API");

  const dataFetch = async (role, analysisType, id, permission) => {
    setLoading(true);
    let { submitSuccess, responseBody, submitError } = await submitForm('fetchTrend', { role, analysisType, id, permission });

    if (submitSuccess) {
      console.log(responseBody.data);
      // Sort depositHistory and depositMonitor by most recent date
      const sortedDepositHistory = responseBody.data?.depositHistory?.sort((a, b) => new Date(b.DATE) - new Date(a.DATE));
      const sortedDepositMonitor = responseBody.data?.depositMonitor?.sort((a, b) => new Date(b.DATE) - new Date(a.DATE));
      setData({
        depositHistory: sortedDepositHistory,
        depositMonitor: sortedDepositMonitor
      });
    }

    setLoading(false);
  };

  // Helper function to generate categories
  const generateCategory = (item, analysisType) => {
    const day = item?.DAY;
    const month = item?.MONTH;
    const year = item?.YEAR;
    const week = item?.WEEK;

    switch (analysisType) {
      case "day":
        return day && month && year ? `${day}/${month}/${year}` : null;
      case "month":
        return month && year ? `${month}/${year}` : null;
      case "week":
        return week && year ? `${week}/${year}` : null;
      default:
        return year ? `${year}` : null;
    }
  };

  // Ensure all categories are aligned across datasets
  const getAllCategories = (data, analysisType) => {
    if (!data?.depositHistory || !data?.depositMonitor) {
      return []; // Return an empty array if data is missing or empty
    }
    const depositCategories = data?.depositHistory?.map(item => generateCategory(item, analysisType)).filter(Boolean);
    const depositMonitorCategories = data?.depositMonitor?.map(item => generateCategory(item, analysisType)).filter(Boolean);

    return [...new Set([...depositCategories, ...depositMonitorCategories])]; // Combine and remove duplicates
  };

  const categories = getAllCategories(data, analysisType);

  // Get data for the chart with defaulting to 0 if no matching category
  // const chartData = [
  //   {
  //     name: "Withdrawal",
  //     data: categories.map(category => {
  //       const item = data?.depositHistory?.find(item => generateCategory(item, analysisType) === category);
  //       return item ? item?.TOTALAMOUNT : 0; // Convert to millions
  //     }),
  //   },
  //   {
  //     name: "Deposit",
  //     data: categories.map(category => {
  //       const item = data?.depositMonitor?.find(item => generateCategory(item, analysisType) === category);
  //       return item ? item?.TOTALAMOUNT : 0; // Convert to millions
  //     }),
  //   },
  // ];

  return (
    <DashboardLayout>
      <Box p={4}>
        <Grid templateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} gap={4} className='no-print'>
          <Text mb={4} fontSize="11" fontWeight={600}>
            <SelectByPermission userPermission={userPermission} user={user} handleSelectChangeCustom={handleSelectChange}/> 
          </Text>
          <Select value={analysisType} onChange={e => setAnalysisType(e.target.value)} fontSize={12} bg="white">
            <option></option>
            <option value="day">Day by Day</option>
            <option value="month">Month by Month</option>
            <option value="week">Week by Week</option>
            <option value="year">Year by Year</option>
          </Select>
        </Grid>
        <Box justifyContent={'right'} display={'flex'} mb={3} className='no-print'>
          <Button onClick={searchButton} bg={"darkblue"} color={'white'} fontSize={12}
          _hover={
            {"bg": "darkblue"}}
          > Submit</Button>
        </Box>

        {loading ? <Spinner size="lg" /> : 
          <Box py={2} background={'white'} borderRadius={10}>
            <ReportHeader />
            <Text fontSize={'18px'} fontWeight={600} justifyContent="center" display={'flex'} alignSelf={'center'}>
              {analysisType.toUpperCase()} BY {analysisType.toUpperCase()} TREND ON SAVINGS AND WITHDRAWAL
            </Text>
            <Box position="relative" width="100%" height="auto">
            <ChartComponent 
              categories={categories}
              dataset={[
                {
                  name: "Withdrawal",
                  color: "#FF0000",
                  data: categories?.map(category => {
                    const item = data?.depositHistory?.find(item => generateCategory(item, analysisType) === category);
                    
                    return item ? item?.TOTALAMOUNT : 0; // Convert to millions
                  }),
                },
                {
                  name: "Deposit",
                  color: "#008000",
                  data: categories?.map(category => {
                    const item = data?.depositMonitor?.find(item => generateCategory(item, analysisType) === category);
                    return item ? item?.TOTALAMOUNT : 0; // Convert to millions
                  }),
                },
              ]}
              type="area"
            />
            </Box>
            <SimpleGrid columns={[1, 2]} spacing={4}>
              <Table variant="simple" mt={8} fontSize={12}>
                <Thead>
                  <Tr>
                    <Th>Withdrawal Period</Th>
                    <Th>Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.depositHistory?.map((item) => (
                    <Tr key={item.id}>
                      <Td>{generateCategory(item, analysisType)}</Td>
                      <Td>{Number(item.TOTALAMOUNT).toLocaleString()}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Table variant="simple" mt={8} fontSize={12}>
                <Thead>
                  <Tr>
                    <Th>Deposit Period</Th>
                    <Th>Total Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.depositMonitor?.map((item) => (
                    <Tr key={item.id}>
                      <Td>{generateCategory(item, analysisType)}</Td>
                      <Td>{Number(item.TOTALAMOUNT).toLocaleString()}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </SimpleGrid>
          </Box>
        }
      </Box>
    </DashboardLayout>
  );
};

export default TrendAnalysisPage;
