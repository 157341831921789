import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  VStack,
  Text,
  Heading,
  Divider,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import { products } from '../api';
import useApi from '../hooks/useApi';

const ScrollableProductList = ({ onSelectionChange }) => {
  const { data: product, loading, error, execute } = useApi(products);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [mainProducts, setMainProducts] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [selectedParentIds, setSelectedParentIds] = useState([]);

  useEffect(() => {
    execute(); // Fetch product data
  }, [execute]);

  // Filter and sort products alphabetically
  useEffect(() => {
    if (product && product.length > 0) {
      const main = product
        .filter((item) => !item.parent)
        .sort((a, b) => a.name.localeCompare(b.name));
      const sub = product
        .filter((item) => item.parent)
        .sort((a, b) => a.name.localeCompare(b.name));
      setMainProducts(main);
      setSubProducts(sub);
    }
  }, [product]);

  // Notify parent of selection changes
  useEffect(() => {
    if (onSelectionChange) {
      const numericSelectedProducts = selectedProducts.filter((id) =>
        Number.isFinite(id)
      );
      onSelectionChange(numericSelectedProducts);
    }
  }, [selectedProducts, onSelectionChange]);

  const getProductId = (item) => (typeof item.id === 'number' ? item.id : item._id);

  const handleCheckboxChange = (productId, isParentWithSubcategories = false) => {
    if (isParentWithSubcategories) {
      setSelectedParentIds((prev) =>
        prev.includes(productId)
          ? prev.filter((id) => id !== productId)
          : [...prev, productId]
      );
    }
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId]
    );
  };

  const filteredSubProducts = useMemo(
    () => subProducts.filter((item) => selectedParentIds.includes(item.parent)),
    [selectedParentIds, subProducts]
  );

  const handleSelectAll = (isChecked) => {
    const allIds = [...mainProducts, ...subProducts].map(getProductId);
    setSelectedProducts(isChecked ? allIds : []);
    setSelectedParentIds(isChecked ? mainProducts.map(getProductId) : []);
  };

  const isAllSelected =
    mainProducts.length + subProducts.length > 0 &&
    selectedProducts.length === mainProducts.length + subProducts.length;

  if (loading) return <Spinner />;
  if (error) return <Text color="red.500">Failed to load products.</Text>;

  return (
    <Flex>
      {/* Main Products Section */}
      <Box
        p={4}
        w="300px"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        maxH="200px"
      >
        <Heading fontSize={11} mb={1}>
          Select Products
        </Heading>
        <Divider mb={1} />
        <Box
          maxH="100px"
          overflowY="auto"
          borderWidth="1px"
          borderRadius="md"
          p={2}
          sx={{
            '&::-webkit-scrollbar': { width: '8px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#2E456B' },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#FEA002' },
            '&::-webkit-scrollbar-track': { backgroundColor: '#F0F0F0' },
          }}
        >
          <VStack align="start" spacing={3}>
            {/* Select All */}
            <Checkbox
              isChecked={isAllSelected}
              onChange={(e) => handleSelectAll(e.target.checked)}
            >
              <Text fontSize={11}>All</Text>
            </Checkbox>
            {/* Main Products */}
            {mainProducts.map((item) => (
              <Checkbox
                key={item._id}
                isChecked={selectedProducts.includes(getProductId(item))}
                onChange={() => handleCheckboxChange(getProductId(item), true)}
              >
                <Text fontSize={11}>{item.name}</Text>
              </Checkbox>
            ))}
          </VStack>
        </Box>
      </Box>

      {/* Sub-Products Section */}
      <Box
        p={4}
        w="200px"
        ml={2}
        maxH="150px"
        overflowY="auto"
        borderWidth="1px"
        borderRadius="md"
        sx={{
          '&::-webkit-scrollbar': { width: '8px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#2E456B' },
          '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#FEA002' },
          '&::-webkit-scrollbar-track': { backgroundColor: '#F0F0F0' },
        }}
      >
        {selectedParentIds.length === 0 ? (
          <Text>No parent categories selected.</Text>
        ) : filteredSubProducts.length === 0 ? (
          <Text>No sub-categories available.</Text>
        ) : (
          <Box>
            <Heading fontSize={11} mb={1}>
              Sub Categories
            </Heading>
            <Divider mb={1} />
            <VStack align="start" spacing={3}>
              {filteredSubProducts.map((subItem) => (
                <Checkbox
                  key={subItem._id}
                  isChecked={selectedProducts.includes(getProductId(subItem))}
                  onChange={() => handleCheckboxChange(getProductId(subItem))}
                >
                  <Text fontSize={11}>{subItem.name}</Text>
                </Checkbox>
              ))}
            </VStack>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default ScrollableProductList;
