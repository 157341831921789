// src/routes.js

import { lazy } from 'react';
import { authGuard } from '../auth/auth-guard';
import LoanAppraisal from '../loans/pages/LoanAppraisal';
import CollectionUsers from '../savings/pages/collectionsPerUser';
import SavingsTarget from '../savings/pages/SavingsTarget';
import TrendAnalysisPage from '../savings/pages/trendAnalysis';
import ReportPage from '../savings/pages/savingsReport';
import MassMarketing from '../savings/pages/mass';
import ChangePassword from '../common/pages/ChangePassword';
import SetBudget from '../savings/pages/Budget';
import AccountPage from '../savings/pages/AccountPage';
import AccountHistoryPage from '../savings/pages/AccountHistory';
const LoanTable = lazy(() => import('../loans/pages/LoanTable'));
const View = lazy(() => import('../common/pages/View'));
const SMEForm = lazy(() => import('../loans/pages/SMEForm'));
const RetailForm = lazy(() => import('../loans/pages/RetailForm'));
const DashboardPage = lazy(() => import('../common/pages/Dashboard'));
const Loan = lazy(() => import('../loans/pages/Loan'));
const Loans = lazy(() => import('../loans/pages/Loans'));
const FileManager = lazy(() => import('../file-management/pages/FileManager'));
const SavingsDashboard = lazy(() => import('../savings/pages/index'));
const UserManager = lazy(()=> import('../admin/pages/UserManager.js'));
const BusinessUnitManager = lazy(()=> import('../admin/pages/BusinessUnitManager.js'));
const PermissionManager = lazy(()=> import('../admin/pages/PermissionsManager.js'));
const RolesManager = lazy(()=> import('../admin/pages/RolesManager.js'));
const DepartmentManager = lazy(()=> import('../admin/pages/DepartmentManager.js'));
const LoginPage = lazy(()=> import('../common/pages/Login.js'))

const routes = [
  {
    path: '/',
    exact: true,
    component: <DashboardPage />,
  },
  {
    path: '/login',
    exact: true,
    component: <LoginPage />,
  },
  {
    path: '/accounts/:type/:status/:id',
    exact: true,
    component: <AccountPage />,
  },{
    path: '/account_history/:id',
    exact: true,
    component: <AccountHistoryPage />,
  },
  {
    path: '/dashboard',
    component: <DashboardPage />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/view',
    component: <View />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/sme_form',
    component: <SMEForm />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/retail_form',
    component: <RetailForm />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/sme_form/:id',
    component: <SMEForm />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/retail_form/:id',
    component: <RetailForm />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/loans',
    component: <Loans />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/loans/:id',
    component: <Loan />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/:status/:type',
    component: <LoanTable />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/appraisal/:id',
    component: <LoanAppraisal />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/roles',
    component: <RolesManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/file_manager',
    component: <FileManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/savings',
    component: <SavingsDashboard />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/collections',
    component: <CollectionUsers />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/users',
    component: <UserManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/BusinessUnites',
    component: <BusinessUnitManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/permissions',
    component: <PermissionManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/roles',
    component: <RolesManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/Departments',
    component: <DepartmentManager />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/savings-target',
    component: <SavingsTarget />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/trend',
    component: <TrendAnalysisPage />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/report',
    component: <ReportPage />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/mass',
    component: <MassMarketing />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/change_password',
    component: <ChangePassword />,
    guards: [authGuard], // Apply the authGuard
  },
  {
    path: '/budget',
    component: <SetBudget />,
    guards: [authGuard], // Apply the authGuard
  }

];

export default routes;
