import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Spinner,
  Text,
  Button,
  Input,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useApi from '../../common/hooks/useApi';
import { submitForm } from '../../common/api';
import DashboardLayout from '../../common/components/DashboardLayout';

const AccountHistoryPage = () => {
  const { id } = useParams();
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { loading, execute } = useApi(submitForm);

  const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  // Fetch account history with filters
  const fetchAccountHistory = async () => {
    try {
      const result = await execute('fetch/accountHistory', {
        ACCT_NO: id,
        startDate,
        endDate,
      });
      if (result?.data) {
        setHistory(result.data);
        setCurrentPage(1); // Reset to the first page when fetching new data
      }
    } catch (err) {
      console.error('Failed to fetch account history:', err);
    }
  };

  // Trigger the API call on filter button click
  const handleFilter = () => {
    fetchAccountHistory(); // Fetch the filtered data
  };

  // Pagination logic
  const totalItems = history.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <DashboardLayout>
      <Box p={10}>
        <Text fontSize="2xl" mb={4}>
          Account History for {id}
        </Text>
        <Flex mb={4} alignItems="center" gap={4}>
          <Box>
            <Text fontSize="sm" mb={1}>
              Start Date
            </Text>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontSize="sm" mb={1}>
              End Date
            </Text>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Box>
          <Button bg="darkblue" _hover={{ bg: "darkblue" }} onClick={handleFilter}>
            Filter
          </Button>
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" my={4}>
            <Spinner size="lg" />
            <Text ml={4}>Loading account history...</Text>
          </Flex>
        ) : (
          <>
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.TRAN_DT}</Td>
                      <Td>{item.TXN_AMT.toLocaleString() || 0}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="2" textAlign="center">
                      No history found for this account.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            <Flex mt={4} justifyContent="center" alignItems="center">
              <Button
                bg={"darkblue"}
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                isDisabled={currentPage === 1}
                mr={2}
              >
                Previous
              </Button>
              <Text>
                Page {currentPage} of {totalPages}
              </Text>
              <Button
                bg={"darkblue"}
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                isDisabled={currentPage === totalPages}
                ml={2}
              >
                Next
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default AccountHistoryPage;
